import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const _handleItemClick = (shop, onClick, type, event) => {
    if (type === 'item') {
        event.preventDefault();

        if (onClick) {
            onClick('shop', shop.id, shop.link);
        } else {
            window.location = shop.link;
        }
    } else {
        window.open(shop.link, '_blank');
    }
};

function ShopItemDisplay({
                             shop,
                             isSelected = false,
                             onClick
                         }) {
    return (
        <li className={classNames(`hoverable search-inline-item search-inline-shop search-inline-shop-${shop.trade.toLowerCase()}`, {selected: isSelected})}
            onClick={_handleItemClick.bind(undefined, shop, onClick, 'item')}>
            <div className="row">
                <div className="col s2 search-inline-icon">
                    <span className="icon-sprite shop-type-icon"/>
                </div>

                <div className="col s10">
                <span className="search-inline-title">
                    {shop.name}
                </span>

                    <div className="search-inline-summary">
                        {shop.summary}
                    </div>
                </div>

                <div className="search-inline-link">
                    <a className="search-inline-link-to"
                       href={shop.link}
                       onClick={_handleItemClick.bind(null, shop, onClick, 'link')}>
                    <span className="material-icons"
                          data-icon="add_circle"
                          aria-hidden="true"/>
                    </a>
                </div>
            </div>
        </li>
    );
}

ShopItemDisplay.propTypes = {
    shop: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func
};

export default React.memo(ShopItemDisplay);

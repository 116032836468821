import React from 'react';
import PropTypes from 'prop-types';
import {createRoot} from 'react-dom/client';

import {
    connect,
    Provider
} from 'react-redux';

import $ from 'jquery';

import {
    configureStore
} from '@js/stores';

import {
    fetchAutocomplete
} from '@js/actions';

import I18n from '@js/modules/translations';
import * as Utils from '@js/modules/utils';

import history from '@js/components/modules/history';

import Input from '@js/components/materialize/input';

import RideItemDisplay from '@js/components/rides/display/item';
import ShopItemDisplay from '@js/components/shops/display/item';
import ProductItemDisplay from '@js/components/products/display/item';

import '@js/modules/fullScreen';


class SearchModal extends React.Component {
    static propTypes = {
        // From connect
        rideResults: PropTypes.array,
        shopResults: PropTypes.array,
        productResults: PropTypes.array,
        fetchAutocomplete: PropTypes.func,
        // From history
        addToHistory: PropTypes.func,
        onHistoryChanged: PropTypes.func
    };

    constructor(props) {
        super(props);

        this._$searchModal = null;
        this._$searchToggleButtons = null;

        this._isOpen = false;

        props.onHistoryChanged('searchModal', this._handleHistory);
    }

    state = {
        advancedLinkValue: window.searchUrl
    };

    componentDidMount() {
        this._$searchModal = $('#search-bar');
        this._$searchToggleButtons = $('#search-toggle');

        this._$searchModal.FullScreen({
            duration: 600,
            fixScrollbar: true,
            onShow: () => {
                this._isOpen = true;
                this._$searchModal.find('input')
                    .focus();
            },
            onHide: () => {
                this._isOpen = false;
                this._$searchModal.find('input')
                    .focus();
            }
        });

        this._$searchToggleButtons.click((event) => {
            // Return if home page
            if (Utils.getUrlPaths()
                .compact().length === 0) {
                return event;
            }

            event.preventDefault();
            event.stopPropagation();
            this._$searchModal.FullScreen(undefined, event);

            this.props.addToHistory({searchModal: {open: true}});
        });

        this._$searchModal.on('keyup', (event) => {
            const keyPressed = event.keyCode || event.which;

            if (Utils.NAVIGATION_KEYMAP[keyPressed] === 'esc') {
                this._$searchModal.FullScreen('hide');
            }
        });
    }

    shouldComponentUpdate() {
        return this._isOpen;
    }

    _handleClose = (event) => {
        event.preventDefault();

        this._$searchModal.FullScreen('hide');
    };

    _handleHistory = (searchModal) => {
        searchModal ||= {open: false};

        if (searchModal && searchModal.open) {
            this._$searchModal.FullScreen();
        } else {
            this._$searchModal.FullScreen('hide');
        }
    };

    _fetchResults = (event) => {
        const value = event.target.value;

        if (Utils.isPresent(value)) {
            this.props.fetchAutocomplete({
                selectedTypes: 'all',
                query: value,
                limit: 6
            });

            this.setState({
                advancedLinkValue: window.searchUrl + '?query=' + encodeURIComponent(value)
            });
        }
    };

    _handleItemClick = (type, elementId, elementLink) => {
        window.location = elementLink;
    };

    _handleSearchClick = (event) => {
        event.preventDefault();

        window.location.href = this.state.advancedLinkValue;
    };

    _handleSubmit = (event) => {
        event.preventDefault();

        const $searchModal = $('#search-bar');
        $searchModal.find('input')
            .blur();

        this._fetchResults(event);
    };

    render() {
        return (
            <div id="search-bar"
                 className="search-bar">
                <div className="layer-overlay"/>
                <div className="layer-content">
                    <a id="search-toggle-button"
                       className="search-toggle"
                       href="#"
                       onClick={this._handleClose}>
                        <span className="material-icons"
                              data-icon="reply"
                              aria-hidden="true"/>
                    </a>

                    <form id="search-modal"
                          onSubmit={this._handleSubmit}
                          acceptCharset="UTF-8"
                          autoComplete="off"
                          noValidate="novalidate">
                        <Input id="search-input"
                               placeholder={I18n.t('js.search.modal.placeholder')}
                               icon="search"
                               onInput={this._fetchResults}/>

                        <div className="search-results">
                            <div className="row">
                                <div className="col s12 l4">
                                    <h4 className="search-modal-title search-modal-ride">
                                        {I18n.t('js.search.modal.categories.rides')}
                                    </h4>
                                    {
                                        this.props.rideResults?.length > 0 &&
                                        <ul className="search-inline">
                                            {
                                                this.props.rideResults.limit(5)
                                                    .map((ride) => (
                                                        <RideItemDisplay key={ride.id}
                                                                         ride={ride}
                                                                         onClick={this._handleItemClick}/>
                                                    ))
                                            }
                                        </ul>
                                    }

                                    {
                                        (!this.props.rideResults || this.props.rideResults.length === 0) &&
                                        I18n.t('js.search.modal.no_results')
                                    }
                                </div>

                                <div className="col s12 l4">
                                    <h4 className="search-modal-title search-modal-shop">
                                        {I18n.t('js.search.modal.categories.shops')}
                                    </h4>
                                    {
                                        this.props.shopResults?.length > 0 &&
                                        <ul className="search-inline">
                                            {
                                                this.props.shopResults.limit(5)
                                                    .map((shop) => (
                                                        <ShopItemDisplay key={shop.id}
                                                                         shop={shop}
                                                                         onClick={this._handleItemClick}/>
                                                    ))
                                            }
                                        </ul>
                                    }
                                    {
                                        (!this.props.shopResults || this.props.shopResults.length === 0) &&
                                        I18n.t('js.search.modal.no_results')
                                    }
                                </div>

                                <div className="col s12 l4">
                                    <h4 className="search-modal-title search-modal-product">
                                        {I18n.t('js.search.modal.categories.products')}
                                    </h4>
                                    {
                                        this.props.productResults?.length > 0 &&
                                        <ul className="search-inline">
                                            {
                                                this.props.productResults.limit(5)
                                                    .map((product) => (
                                                        <ProductItemDisplay key={product.id}
                                                                            product={product}
                                                                            onClick={this._handleItemClick}/>
                                                    ))
                                            }
                                        </ul>
                                    }
                                    {
                                        (!this.props.productResults || this.props.productResults.length === 0) &&
                                        I18n.t('js.search.modal.no_results')
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="center-align">
                            <button className="btn waves-effect waves-light search-modal-btn"
                                    type="button"
                                    onClick={this._handleSearchClick}>
                                <span className="material-icons left"
                                      data-icon="explore"
                                      aria-hidden="true"/>
                                {I18n.t('js.search.modal.explore')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const ConnectedSearchModal = connect((state) => ({
    rideResults: state.autocompleteState.rides,
    shopResults: state.autocompleteState.shops,
    productResults: state.autocompleteState.products
}), {
    fetchAutocomplete
})(history(SearchModal));

setTimeout(() => {
    const searchElement = document.getElementById('search-modal-component');
    if (!searchElement) {
        return;
    }

    const root = createRoot(searchElement);
    root.render(
        <Provider store={configureStore}>
            <ConnectedSearchModal/>
        </Provider>
    );
}, 100);

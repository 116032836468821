import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import * as Utils from '@js/modules/utils';

export default class Input extends React.Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        id: PropTypes.string.isRequired,
        className: PropTypes.string,
        wrapperClassName: PropTypes.string,
        title: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.object,
            PropTypes.string
        ]),
        placeholder: PropTypes.string,
        explanation: PropTypes.string,
        type: PropTypes.string,
        isHorizontal: PropTypes.bool,
        isRequired: PropTypes.bool,
        isReadOnly: PropTypes.bool,
        isDisabled: PropTypes.bool,
        labelClass: PropTypes.string,
        name: PropTypes.string,
        multipleId: PropTypes.number,
        hasClear: PropTypes.bool,
        icon: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.object,
            PropTypes.string
        ]),
        step: PropTypes.number,
        min: PropTypes.number,
        max: PropTypes.number,
        minLength: PropTypes.number,
        maxLength: PropTypes.number,
        hasAutoFocus: PropTypes.bool,
        autoComplete: PropTypes.bool,
        isChangeOnUpdated: PropTypes.bool,
        onFocus: PropTypes.func,
        onChange: PropTypes.func,
        onInput: PropTypes.func,
        onKeyDown: PropTypes.func,
        onKeyPress: PropTypes.func,
        onKeyUp: PropTypes.func,
        onBlur: PropTypes.func,
        characterCount: PropTypes.number,
        mask: PropTypes.object,
        validator: PropTypes.object
    };

    static defaultProps = {
        type: 'text',
        children: '',
        placeholder: ' ',
        isHorizontal: false,
        isRequired: false,
        isReadOnly: false,
        isDisabled: false,
        hasClear: false,
        isChangeOnUpdated: true
    };

    constructor(props) {
        super(props);

        this._inputRef = React.createRef();
    }

    state = {
        value: Utils.isPresent(this.props.children) ? this.props.children : ''
    };

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.value !== nextState.value || this.props.className !== nextProps.className || this.props.isRequired !== nextProps.isRequired;
    }

    componentDidUpdate(prevProps) {
        if (this.props.isChangeOnUpdated && prevProps.children !== this.state.value) {
            if (this.props.onChange) {
                this.props.onChange(this.state.value);
            }
        }
    }

    _handleChange = (event) => {
        const newValue = event.target.value;

        this.setState({value: newValue});

        if (this.props.onChange) {
            this.props.onChange(newValue);
        }
    };

    _handleClearInput = (event) => {
        event.preventDefault();

        this.setState({value: ''});

        if (this.props.onChange) {
            this.props.onChange('');
        } else if (this.props.onInput) {
            this.props.onInput({target: {value: ''}});
        }
    };

    focus = () => {
        this._inputRef.current.focus();
    };

    value = () => {
        return this._inputRef.current.value;
    };

    setValue = (newValue) => {
        this.setState({
            value: newValue
        });
    };

    render() {
        const id = this.props.multipleId ? this.props.id + '_' + this.props.multipleId : this.props.id;

        let name = this.props.name;
        if (!name && this.props.id.indexOf('_') !== -1) {
            if (this.props.multipleId) {
                name = this.props.id.replace('_', `[${this.props.multipleId}][`) + ']';
            } else {
                name = this.props.id.replace('_', '[') + ']';
            }
        }

        const wrapperClass = classNames(this.props.wrapperClassName, {
            'input-form': this.props.type !== 'hidden'
        });

        const fieldClass = classNames({
            'input-field': !this.props.isHorizontal && this.props.type !== 'hidden',
            'input-horizontal-field': this.props.isHorizontal,
            'required-field': this.props.isRequired,
            'row': this.props.isHorizontal
        });

        const iconClass = classNames(
            'material-icons',
            'prefix',
            {
                active: !!this.props.children || this.state.value
            }
        );

        const labelClass = classNames(
            this.props.labelClass,
            {
                active: !!this.props.children || this.state.value || this.props.placeholder,
                'col m4': this.props.isHorizontal
            }
        );

        const inputClass = classNames(
            this.props.className,
            'validate',
            {
                'col m8': this.props.isHorizontal
            }
        );

        return (
            <div className={wrapperClass}>
                <div className={fieldClass}>
                    {
                        !!(this.props.icon && Utils.is().isString(this.props.icon)) &&
                        <span className={iconClass}
                              data-icon={this.props.icon}
                              aria-hidden="true"/>
                    }

                    {
                        !!(this.props.icon && Utils.is().isObject(this.props.icon)) &&
                        this.props.icon
                    }

                    {
                        !!(this.props.title && this.props.isHorizontal) &&
                        <label htmlFor={this.props.id}
                               className={labelClass}>
                            {this.props.title}
                        </label>
                    }

                    <input ref={this._inputRef}
                           id={id}
                           className={inputClass}
                           type={this.props.type}
                           required={this.props.isRequired}
                           disabled={this.props.isDisabled}
                           readOnly={this.props.isReadOnly}
                           placeholder={this.props.placeholder}
                           name={name}
                           step={this.props.step}
                           min={this.props.min}
                           max={this.props.max}
                           minLength={this.props.minLength}
                           maxLength={this.props.maxLength}
                           autoFocus={this.props.hasAutoFocus}
                           autoComplete={this.props.autoComplete}
                           onFocus={this.props.onFocus}
                           onChange={this._handleChange}
                           onInput={this.props.onInput}
                           onKeyDown={this.props.onKeyDown}
                           onKeyPress={this.props.onKeyPress}
                           onKeyUp={this.props.onKeyUp}
                           onBlur={this.props.onBlur}
                           value={this.state.value}
                           data-length={this.props.characterCount}
                           {...this.props.validator}
                           {...this.props.mask}/>

                    {
                        !!(this.props.hasClear && this.state.value && this.state.value.length > 0) &&
                        <a className="clear-input"
                           onClick={this._handleClearInput}>
                                <span className="material-icons"
                                      data-icon="close"
                                      aria-hidden="true"/>
                        </a>
                    }

                    {
                        !!(this.props.title && !this.props.isHorizontal) &&
                        <label htmlFor={this.props.id}
                               className={labelClass}>
                            {this.props.title}
                        </label>
                    }

                    {
                        !!this.props.explanation &&
                        <span className="helper-text">
                            {this.props.explanation}
                        </span>
                    }
                </div>
            </div>
        );
    }
}

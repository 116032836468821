import {createRoot} from 'react-dom/client';

// import {
//     Provider
// } from 'react-redux';

import * as Utils from '@js/modules/utils';

import {
    configureStore
} from '@js/stores';

import {
    synchronizeBookmarks
} from '@js/actions';

// Load Login and Signup modals
import ConnectionLoader from '@js/loaders/connection';

if (Utils.isEmpty(window.currentUserId)) {
    setTimeout(() => {
        ConnectionLoader()
            .then(({
                       Login,
                       Signup
                   }) => {
                const signupElement = document.getElementById('signup-modal-component');
                const loginElement = document.getElementById('login-modal-component');

                if (!signupElement || !loginElement) {
                    return;
                }

                const signupRoot = createRoot(signupElement);
                signupRoot.render(
                    <Signup launcherId="signup-link"/>
                );

                const loginRoot = createRoot(loginElement);
                loginRoot.render(
                    <Login launcherId="login-link"/>
                );
            });
    }, 100);
}

// Synchronize local data with user account just after sign in ou up
if (window.firstConnection) {
    configureStore.dispatch(synchronizeBookmarks());
}

// Comment chat for now: not used
// // Chat minimized
// import MinifiedChat from '../users/chat/minified';
// const root = createRoot(document.getElementById('chat-component'));
// root.render(
//     <Provider store={configureStore}>
//         <MinifiedChat/>
//     </Provider>
// );


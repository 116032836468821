import * as M from '@materializecss/materialize';

import * as Utils from '@js/modules/utils';

require('@js/application');
// require('../pwa');

require('@js/modules/validation');

require('@js/common/megaMenu');
require('@js/components/users/account');
require('@js/components/search/modal');

// Reload page if connected to bypass browser cache
function checkUserState() {
    if (process.env.NODE_ENV !== 'production') {
        return;
    }

    setTimeout(function () {
        if ((Utils.getCookie('user.connected_id') && !window.currentUserId) || (Utils.getCookie('admin.connected_id') && !window.currentAdminId) || (window.currentUserId && !Utils.getCookie('user.connected_id')) || (window.currentAdminId && !Utils.getCookie('admin.connected_id'))) {
            const timestamp = Date.now();
            const urlParams = window.location.search;
            const newUrl = window.location + (urlParams ? urlParams + '&' : '?') + `_=${timestamp}`;
            window.location.replace(newUrl);
        }
    }, 10);
}

if (document.readyState !== 'loading') {
    checkUserState();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        checkUserState();
    });
}

// Clean timestamp after connection
if (window.location.search.includes('_=')) {
    const url = new URL(window.location);
    url.searchParams.delete('_');
    window.history.replaceState(null, null, url);
}

// Initialize all SideNav
const sidenavs = document.querySelectorAll('.sidenav');
M.Sidenav.init(sidenavs, {
    edge: 'left'
});

// Initialize dropdowns
const dropdowns = document.querySelectorAll('.dropdown-trigger');
M.Dropdown.init(dropdowns, {
    coverTrigger: false,
    constrainWidth: false
});

import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import Rating from '../../theme/rating';

const _handleItemClick = (product, onClick, type, event) => {
    if (type === 'item') {
        event.preventDefault();

        if (onClick) {
            onClick('product', product.id, product.link);
        } else {
            window.location = product.link;
        }
    } else {
        window.open(product.link, '_blank');
    }
};

function ProductItemDisplay({
                                product,
                                isSelected = false,
                                onClick
                            }) {
    return (
        <li className={classNames(`hoverable search-inline-item search-inline-product search-inline-product-${product.trade.toLowerCase()}`, {selected: isSelected})}
            onClick={_handleItemClick.bind(undefined, product, onClick, 'item')}>
            <div className="row">
                <div className="col s2 search-inline-icon">
                    <span className="icon-sprite product-type-icon"/>
                </div>

                <div className="col s10">
                    <span className="search-inline-title">
                        {product.name}
                    </span>

                    <div className="search-inline-summary">
                        {product.summary}
                    </div>
                </div>

                {
                    !!product.notation &&
                    <div className="search-inline-notation">
                        <Rating initialRating={product.notation}
                                size="small"
                                isCentered={false}/>
                    </div>
                }

                <div className="search-inline-link">
                    <a className="search-inline-link-to"
                       target="_blank"
                       href={product.link}
                       onClick={_handleItemClick.bind(undefined, product, onClick, 'link')}>
                    <span className="material-icons"
                          data-icon="add_circle"
                          aria-hidden="true"/>
                    </a>
                </div>
            </div>
        </li>
    );
}

ProductItemDisplay.propTypes = {
    product: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func
};

export default React.memo(ProductItemDisplay);

import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import Rating from '../../theme/rating';

const _handleItemClick = (ride, onClick, type, event) => {
    if (type === 'item') {
        event.preventDefault();

        if (onClick) {
            onClick('ride', ride.id, ride.link);
        } else {
            window.location = ride.link;
        }
    } else {
        window.open(ride.link, '_blank');
    }
};

function RideItemDisplay({
                             ride,
                             isSelected = false,
                             onClick
                         }) {
    return (
        <li className={classNames(`hoverable search-inline-item search-inline-ride search-inline-${ride.icon}`, {selected: isSelected})}
            onClick={_handleItemClick.bind(undefined, ride, onClick, 'item')}>
            <div className="row">
                <div className="col s2 search-inline-icon">
                    <span className="icon-sprite ride-type-icon"/>
                </div>

                <div className="col s10">
                    <span className="search-inline-title">
                        {ride.name}
                    </span>

                    <div className="search-inline-summary">
                        {ride.summary}
                    </div>
                </div>
            </div>

            {
                !!ride.notation &&
                <div className="search-inline-notation">
                    <Rating initialRating={ride.notation}
                            size="small"
                            isCentered={false}/>
                </div>
            }

            <div className="search-inline-link">
                <a className="search-inline-link-to"
                   href={ride.link}
                   onClick={_handleItemClick.bind(null, ride, onClick, 'link')}>
                <span className="material-icons"
                      data-icon="add_circle"
                      aria-hidden="true"/>
                </a>
            </div>
        </li>
    );
}

RideItemDisplay.propTypes = {
    ride: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func
};

export default React.memo(RideItemDisplay);
